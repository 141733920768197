// DashboardCharts.js
import React from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styles from "../../styles/dashboard.module.css";
import mainStyle from "../../styles/mainComponents.module.css";

// Registrar los componentes de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function DashboardCharts({ dashboardData }) {
  const { t } = useTranslation();

  if (!dashboardData) {
    return (
      <div className={styles.divPibito}>
        <img className={styles.pibito} src="/img/Pibito.png" alt="" />
        <p className={mainStyle.noData}>{t("noData")}</p>
      </div>
    );
  }

  // Preparar etiquetas
  const labels = ["Goals", "Roles", "Processes", "Interactions"];

  // Inicializar datasets
  const datasets = [];

  // Datos para el promedio personal
  let personalData = [0, 0, 0, 0]; // Valores predeterminados
  if (dashboardData.mediaPersonal) {
    const { Goals, Roles, Processes, Interactions } = dashboardData.mediaPersonal;
    personalData = [Goals, Roles, Processes, Interactions];
  }

  // Agregar el dataset de "personalAverage"
  datasets.push({
    label: t("personalAverage"),
    data: personalData,
    backgroundColor: "#4C87B2", // Color para el promedio personal
    barThickness: 30,
    borderRadius: 5,
  });

  // Datos para vista "Equipo"
  const { mediaEquipo, mediaEquipoEmpresa, mediaEquipoGlobal } = dashboardData;

  datasets.push(
    {
      label: t("teamAverage"),
      data: [
        mediaEquipo.Goals,
        mediaEquipo.Roles,
        mediaEquipo.Processes,
        mediaEquipo.Interactions,
      ],
      backgroundColor: "#48BAA5",
      barThickness: 30,
      borderRadius: 5,
    },
    {
      label: t("companyAverage"),
      data: [
        mediaEquipoEmpresa.Goals,
        mediaEquipoEmpresa.Roles,
        mediaEquipoEmpresa.Processes,
        mediaEquipoEmpresa.Interactions,
      ],
      backgroundColor: "#595959",
      barThickness: 30,
      borderRadius: 5,
    },
    {
      label: t("globalAverage"),
      data: [
        mediaEquipoGlobal.Goals,
        mediaEquipoGlobal.Roles,
        mediaEquipoGlobal.Processes,
        mediaEquipoGlobal.Interactions,
      ],
      backgroundColor: "#e3147f",
      barThickness: 30,
      borderRadius: 5,
    }
  );

  // Opciones para personalizar el gráfico
  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 4,
        ticks: {
          stepSize: 1,
          font: {
            size: 16,
            family: "Inter, sans-serif",
          },
          color: "#e3147f",
          callback: function (value) {
            return value !== 0 ? value : null;
          },
          padding: 10,
          align: "center",
          crossAlign: "near",
        },
        grid: {
          color: "#e3147f",
          lineWidth: 0.25,
          drawBorder: false,
          offset: true,
        },
        border: {
          display: false,
        },
      },
      x: {
        ticks: {
          font: {
            size: 12,
            family: "Inter, sans-serif",
          },
          color: "#e3147f",
          padding: 8,
        },
        grid: {
          borderColor: "#e3147f",
          drawBorder: false,
          color: function (context) {
            return context.index === 0 ? "transparent" : "#e3147f";
          },
          lineWidth: 0.25,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "right",
        labels: {
          font: {
            size: 16,
            family: "Inter, sans-serif",
          },
          color: "#595959",
          padding: 25,
        },
        onHover: function (event) {
          event.native.target.style.cursor = "pointer";
        },
        onLeave: function (event) {
          event.native.target.style.cursor = "default";
        },
      },
    },
  };

  return (
    <div className={styles.divInteriorGrafico}>
      <Bar data={{ labels, datasets }} options={options} />
    </div>
  );
}

export default DashboardCharts;
